import React, { useEffect, useState } from "react";
import searchIcon from "../../../assets/images/search-icon.svg";
import { Accordion } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDetectClickOutside } from "react-detect-click-outside";
// import Pagination from "../../../components/Pagination";
import xmark from "../../../assets/images/xmark.svg";
import Download from "../../../assets/images/download.svg";
import { useSelector, useDispatch } from "react-redux";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { saveAs } from "file-saver";
import NoDataGif from "../../../assets/images/no_data.gif"

import {
    setLoading,
    setReportLoading,
} from "../../../redux/loading/loadingSlice";
import AuthLoader from "../../../components/loader/authLoader";
import CustomPagination from "../../../components/CustomPagination";
import { toastify } from "../../../helpers/toast/toastify";

export default function Reports() {
    const [show, setShow] = useState(false);
    const [startDate, setStartDate] = useState();
    const [filteredHistory, setFilteredHistory] = useState([]);
    const [history, setHistory] = useState([]);
    const [viewReport, setViewReport] = useState("")
    const [page, setPage] = useState(1);
    const ref = useDetectClickOutside({ onTriggered: () => setShow(false) });
    const user = useSelector((state) => state.user.user);
    const api = useApi();
    const dispatch = useDispatch();
    const [selectedHistory, setSelectedHistory] = useState();
    const loaders = useSelector((state) => state?.loader);

    useEffect(() => {
        const startDate = new Date(user?.leaderCommunity?.createdAt);
        const result = getMonthsAndYearsFromDate(startDate);

        setHistory(result);
    }, []);

    useEffect(() => {
        if (selectedHistory) {
            setFilteredHistory([selectedHistory]);
        } else {
            setFilteredHistory([]);
        }
    }, [selectedHistory]);

    useEffect(() => {
        dispatch(setReportLoading(false));
    }, []);

    function getMonthsAndYearsFromDate(startDate) {
        const currentDate = new Date();
        const monthsAndYears = [];

        let currentDatePointer = new Date(startDate);

        while (
            currentDatePointer.getFullYear() < currentDate.getFullYear() ||
            (currentDatePointer.getFullYear() === currentDate.getFullYear() &&
                currentDatePointer.getMonth() <= currentDate.getMonth())
        ) {
            const formattedDate = new Intl.DateTimeFormat("en-US", {
                year: "numeric",
                month: "long",
            }).format(currentDatePointer);

            monthsAndYears.push(formattedDate);

            currentDatePointer.setMonth(currentDatePointer.getMonth() + 1);
        }

        return monthsAndYears;
    }

    useEffect(() => {

        const fetchData = async () => {
            dispatch(setLoading(true))
            try {
                const response = await api("post", "users/getMonthlyReport");
                console.log(response, "==ResponseData");
                setViewReport(response?.data)
                dispatch(setLoading(false))

            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };

        fetchData();
    }, []);

    const downloadCsv = async (date) => {
        dispatch(setReportLoading(true));
        console.log("aaaaaaaaa")
        const response = await api("post", "users/getMonthlyReport", { date });
        if (response?.success) {
            const base64Data = response.base64File;
            const byteCharacters = atob(base64Data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, `${date ? "Report - " + date : "Report - All"}.xlsx`);
            dispatch(setReportLoading(false));
            console.log(response?.data, "dataaaaa")
        } else {
            dispatch(setReportLoading(false));

            toastify("No report found")

            console.log("Error fetching file from backend");
        }
    };

    return (
        <>
            <div className="row justify-content-center h-100 p-top-on-mobile">
                <div className="col-lg-12 d-flex flex-column">
                    <div className="connection reportsJs p-top">
                        {loaders?.reportLoading ? (
                            <AuthLoader component="report" />
                        ) : (
                            <>
                                <div className="mx-536">
                                    <div className="title mb-3">
                                        <h3>Download your monthly reports</h3>
                                    </div>
                                    {history?.length > 0 ? (
                                        <div className="page_title d-flex align-items-center justify-content-end flex-nowrap mb-3">
                                            <div
                                                className="custom-dropdown d-flex"
                                                ref={ref}
                                            >
                                                <button
                                                    className="dropdown-toggle position-unset"
                                                    onClick={() => setShow(!show)}
                                                >
                                                    <img
                                                        className="filter-icon"
                                                        alt="searchIcons"
                                                        src={searchIcon}
                                                    />
                                                </button>
                                                {show && (
                                                    <div className="dropdown-menu">
                                                        <Accordion
                                                            defaultActiveKey={""}
                                                        >
                                                            <div className="d-flex align-items-center justify-content-around p-3">
                                                                {/* <DatePicker
                                            className="px-2 py-2"
                                            selected={startDate}
                                            onChange={(date) => {
                                                setStartDate(date);
                                            }}
                                            placeholderText="Select Date"
                                            maxDate={new Date()}
                                        /> */}
                                                                <select
                                                                    className="w-100 form-control"
                                                                    name="exchange"
                                                                    value={
                                                                        selectedHistory
                                                                    }
                                                                    onChange={(
                                                                        e
                                                                    ) => {
                                                                        setSelectedHistory(
                                                                            e.target
                                                                                .value
                                                                        );
                                                                        setPage(1);
                                                                    }}
                                                                >
                                                                    <option
                                                                        value=""
                                                                        style={{
                                                                            display:
                                                                                "none",
                                                                        }}
                                                                    >
                                                                        Select Month
                                                                    </option>
                                                                    {history?.map(
                                                                        (e) => (
                                                                            <option
                                                                                value={
                                                                                    e
                                                                                }
                                                                            >
                                                                                {e}
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </select>
                                                                <span
                                                                    className="ms-2 cursor-pointer"
                                                                    onClick={() =>
                                                                        setStartDate()
                                                                    }
                                                                >
                                                                    <img
                                                                        className="xmark"
                                                                        src={xmark}
                                                                        alt="xmark"
                                                                        onClick={() => {
                                                                            setSelectedHistory();
                                                                            setShow(
                                                                                false
                                                                            );
                                                                        }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </Accordion>
                                                        <li></li>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    ) : null}
                                    <div className="tradeHistoryReports mt-4">
                                        {(filteredHistory.length > 0
                                            ? filteredHistory
                                            : history
                                        )
                                            ?.filter((e, index) => {
                                                if (
                                                    index < page * 10 &&
                                                    index > page * 10 - 11
                                                ) {
                                                    return e;
                                                }
                                            })
                                            ?.map((e) => (
                                                <div className="d-flex justify-content-between border-bottom pb-3 mb-25">
                                                    <p>{e}</p>
                                                    <img
                                                        src={Download}
                                                        onClick={() =>
                                                            downloadCsv(e)
                                                        }
                                                        className="cursor-pointer me-3"
                                                        alt="Download"
                                                    />
                                                </div>
                                            ))}
                                    </div>

                                    <div className="d-flex mt-93 justify-content-between">
                                        <div className="d-flex align-items-end">
                                            <div className="me-2">
                                                <strong className="fs16">
                                                    Download
                                                </strong>
                                                <p className="fs16">
                                                    All reports (CSV)
                                                </p>
                                            </div>
                                            <img
                                                className="cursor-pointer"
                                                src={Download}
                                                alt="Download"
                                                onClick={() => downloadCsv(new Date().toISOString())}
                                            />
                                        </div>
                                        {/* <Pagination
                                                    setPage={setPage}
                                                    page={page}
                                                    filtered={filteredHistory}
                                                    items={history}
                                                /> */}
                                        <CustomPagination
                                            setPage={setPage}
                                            page={page}
                                            items={history}
                                            filtered={filteredHistory}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>


        </>
    );
}
