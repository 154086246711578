import axios from "axios";
import { useDispatch } from "react-redux";
import { storeUser } from "../../redux/users/usersSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { toastify } from "../toast/toastify";
import { setLoading, setResponseLoading } from "../../redux/loading/loadingSlice";


// export const api = async (method, url, payload) => {
//     if (method === "post") {
//         try {
//             const response = await config.post(
//                 url,
//                 {
//                     ...payload,
//                 },
//                 {
//                     headers: {
//                         Authorization: localStorage.getItem("token"),
//                     },
//                 }
//             );

//             return response?.data;
//         } catch (error) {
//             // console.log({ error });
//             return error?.response?.data || "Error";
//         }
//     } else if (method === "get") {
//         try {
//             const response = await config.get(url, {
//                 headers: {
//                     Authorization: localStorage.getItem("token"),
//                 },
//             });
//             return response?.data;
//         } catch (error) {
//             if (error.response?.status === 401) {
//                 localStorage.removeItem("token");
//                 dispatch(
//                     storeUser({
//                         user: null,
//                         token: null,
//                     })
//                 );

//                 navigate("/login");
//             }

//             return error?.response?.data;
//         }
//     }
// };

const useApi = () => {
    const config = axios.create({
        baseURL: process.env.REACT_APP_API_BASE_URL,
        // baseURL: "https://api.copyyy.it/",
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation()

    const logOut = () => {

        localStorage.removeItem("token");
        localStorage.removeItem("lastVisitedRoute");
        localStorage.removeItem("rememberMe")
        // localStorage.clear()
        dispatch(
            storeUser({
                user: null,
                token: null,
            })
        );

        // login community
        // dispatch(socailSignUp(null))
    };

    // Axios request interceptor
    config.interceptors.request.use(
        (request) => {
            const token = localStorage.getItem("token");
            // const communityName = user?.leaderCommunity?.name
            const allowedPaths = ["/signup", "/member/signup"];
            const communityPathPattern = /^\/community\//;
            const currentPath = location.pathname;
            if (token) {
                request.headers.Authorization = token;
            } else if (!token && (allowedPaths.includes(currentPath) || communityPathPattern.test(currentPath))) {
                request.headers.Authorization = token;
            } else {
                navigate("/login");
                logOut();
            }
            // request.headers.Authorization = token;
            return request;
        },
        (error) => {
            return Promise.reject(error);
        }
    );


    const makeApiCall = async (method, url, payload) => {
        if (method === "post") {
            try {
                const response = await config.post(
                    url,
                    {
                        ...payload,
                    },
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                );

                return response?.data;
            } catch (error) {
                if (error.response?.status === 401) {
                    toastify(
                        "Your session has expired. Please login again to continue trading."
                    );
                    localStorage.setItem(
                        "lastVisitedRoute",
                        window.location.pathname
                    );
                    localStorage.removeItem("token");
                    dispatch(
                        storeUser({
                            user: null,
                            token: null,
                        })
                    );
                } else {
                    return error?.response?.data || "Error";
                }
            }
        } else if (method === "get") {
            try {
                const response = await config.get(url, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                return response?.data;
            } catch (error) {
                if (error.response?.status === 401) {
                    toastify(
                        "Your session has expired. Please login again to continue trading."
                    );
                    localStorage.setItem(
                        "lastVisitedRoute",
                        window.location.pathname
                    );
                    localStorage.removeItem("token");
                    dispatch(
                        storeUser({
                            user: null,
                            token: null,
                        })
                    );
                } else {
                    return error?.response?.data;
                }
            }
        }
    };

    return makeApiCall;
};

export default useApi;
