import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import dashboardIcon from "../assets/images/dashboard.svg";
import exchangeIcon from "../assets/images/dashboardIcon.png";
import SmartphoneIcon from "../assets/images/Smartphone.png";
import AnnounceIcon from "../assets/images/Announce.svg";
import arrowD from "../assets/images/ard.png";
import MoneyIcon from "../assets/images/Money.svg";
import arrowUp from "../assets/images/arrowUp.png";
import Billing from "../assets/images/billing.svg";
import HelpArticles from "../assets/images/Question.svg";
import Issues from "../assets/images/Exclamation.svg";
import Promotions from "../assets/images/Promotion.svg";
import historyIcon from "../assets/images/perform.svg";
import memberIcon from "../assets/images/member.svg";
import Clients from "../assets/images/clients.svg";
import subsIcon from "../assets/images/settings.svg";
import logoutIcon from "../assets/images/logout.svg";
import SmartPhone from "../assets/images/Smartphone.svg";
import supportIcon from "../assets/images/support.png";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storeUser, updateUser } from "../redux/users/usersSlice";
// import { api } from "../helpers/apiHelper/requestHelper";
import LoadingModal from "../components/loader/loadingModal";
import AuthLoader from "../components/loader/authLoader";
import useApi from "../helpers/apiHelper/requestHelper";
import {
  setLoading,
  setSigningYouIn,
  setShowConnectionSuccessModal,
  setShowConnectionFailModal,
} from "../redux/loading/loadingSlice";
import { useTour } from "@reactour/tour";
import { setSidebar } from "../redux/sidebar/sidebarSlice";
import { setAllLinks } from "../redux/links/linksSlice";
import { setCurrentTab } from "../redux/links/linksSlice";
import { socailSignUp } from "../redux/socialSignup/socialSignUp";

export const UserLayout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [onboard, setOnboard] = useState(false);
  const [tradeSubmenuVisible, setTradeSubmenuVisible] = useState(false);
  const [performanceSubmenueVisible, setPerformanceSubmenueVisible] =
    useState(false);
  const [settingSubmenueVisible, setSettingSubmenueVisible] = useState(false);
  const [tradingSubmenuVisible, setTradingSubmenuVisible] = useState(false);
  const [businessSubmenuVisible, setBusinessSubmenuVisible] = useState(false);
  const [appSubmenuVisible, setAppSubmenuVisible] = useState(false);

  const user = useSelector((e) => e?.user?.user);
  const loaders = useSelector((state) => state?.loader);
  const { currentTab } = useSelector((state) => state.links);

  const openSidebar = useSelector((state) => state?.sidebar?.openSidebar);
  const showSidebar = useSelector((state) => state.sidebar?.showSidebar);
  const api = useApi();
  const { isOpen, setIsOpen, currentStep, setCurrentStep } = useTour();

  const tourNavigate = localStorage.getItem("tour");

  const tourClose = localStorage.getItem("tourClose");

  const [isBrave, setIsBrave] = useState(false);

  useEffect(() => {
    const checkBrave = async () => {
      if (navigator.brave && (await navigator.brave.isBrave())) {
        setIsBrave(true);
      }
    };

    checkBrave();
  }, []);

  useEffect(() => {
    if (tourNavigate) {
      if (user?.role === "leader") {
        navigate("/leader/copyTrade", { replace: true });
      } else if (user?.role === "member") {
        navigate("/member/copyTrade", { replace: true });
      } else if (user?.role === "admin") {
        navigate("/admin/copyTrade");
      }
      localStorage.removeItem("tour");
    }
  }, [tourNavigate]);

  useEffect(() => {
    if (onboard) {
      if (user?.role === "leader") {
        navigate("/leader/copyTrade");
      } else if (user?.role === "member") {
        if (user?.firstVisit == true && user?.onboarding?.payment == true) {
          navigate("/member/tradeActive", { replace: true });
        } else {
          navigate("/member/copyTrade", { replace: true });
          dispatch(setCurrentTab("introduction"));
        }
      } else if (user?.role === "admin") {
        navigate("/admin/copyTrade");
      }
      setOnboard(false);
    }
  }, [onboard]);

  useEffect(() => {
    dispatch(setLoading(true));
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
    dispatch(setLoading(false));
    if (!localStorage.getItem("token")) {
      navigate("/");
    }
    // const isBrave = (navigator.brave && (await navigator.brave.isBrave())) || false;
    // if (isBrave) {
    //   console.log("Brave");
    // }
  }, []);

  useEffect(() => {
    if (!user) {
      localStorage.setItem("lastVisitedRoute", window.location.pathname);
      navigate("/login");
    } else {
      checkRoutes();
    }
  }, [user, navigate]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(setSigningYouIn(false));
    }, 5000);

    // Cleanup function to clear the timeout on unmount or re-render
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  const checkRoutes = () => {
    let role = user?.role;
    let route = window.location.pathname;
    if (
      role === "leader" &&
      (route.includes("admin/") || route.includes("member/"))
    ) {
      navigate("/leader/dashboard", { replace: true });
    } else if (
      role === "member" &&
      (route.includes("admin/") || route.includes("leader/"))
    ) {
      navigate("/member/dashboard", { replace: true });
    } else if (
      role === "admin" &&
      (route.includes("member/") || route.includes("leader/"))
    ) {
      navigate("/admin/dashboard", { replace: true });
    }
  };

  useEffect(() => {
    fetchUserData();
    checkRoutes();
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Adds smooth scrolling animation
    });
  }, [window.location.pathname]);

  useEffect(() => {
    getLinks();
  }, []);

  useEffect(() => {}, [loaders]);

  const getLinks = async () => {
    const response = await api("get", "links/index");
    if (response?.status) {
      dispatch(setAllLinks(response?.data));
    } else {
      navigate("login");
      // toastify(response?.message);
    }
  };

  const fetchUserData = async () => {
    const response = await api("get", "users/index");
    if (response?.status) {
      dispatch(storeUser(response?.data));
      localStorage.setItem("token", response?.data?.token);
    }
  };

  const sidebarClick = () => {
    dispatch(setSidebar(!openSidebar));
  };

  const closeSidebar = () => {
    dispatch(setSidebar(false));
  };

  const checkConnected = () => {
    const isBinanceConnected = user?.binanceCredentials?.isConnected;
    const isBybitConnected = user?.bybitCredentials?.isConnected;

    if (isBinanceConnected && isBybitConnected) {
      return (
        <p className="fs18 text_GB text-700">
          CONNECTED:{" "}
          <span className="span-text text_dark6">Binance | Bybit</span>
        </p>
      );
    } else if (isBinanceConnected) {
      return (
        <p className="fs18 text_GB text-700">
          CONNECTED: <span className="span-text text_dark6">Binance</span>
        </p>
      );
    } else if (isBybitConnected) {
      return (
        <p className="fs18 text_GB text-700">
          CONNECTED: <span className="span-text text_dark6">Bybit</span>
        </p>
      );
    } else {
      return <p className="fs18 text_GB text-700">DISCONNECTED</p>;
    }
  };

  const checkConnection = () => {
    const isBinanceConnected = user?.binanceCredentials?.isConnected;
    const isBybitConnected = user?.bybitCredentials?.isConnected;

    if (isBinanceConnected || isBybitConnected) {
      return true;
    } else {
      return false;
    }
  };

  const openSidebarAutomatically = () => {
    dispatch(setSidebar(true));
  };

  const handleErrorModal = () => {
    dispatch(setShowConnectionFailModal(false));
  };

  useEffect(() => {
    if (user) {
      checkConnected();
      checkConnection();
    }
    if (user?.firstVisit && !tourClose) {
      openSidebarAutomatically();
      setIsOpen(true);
    }
  }, []);

  useEffect(() => {
    if (loaders?.showConnectionSuccessModal) {
      setTimeout(() => {
        dispatch(setShowConnectionSuccessModal(false));
      }, 3000);
    }
  }, [loaders?.showConnectionSuccessModal]);

  const checkActiveMenu = (item) => {
    if (item === (window.location.pathname?.split("/")[2] || null)) {
      return "active";
    }
  };

  const toggleSubmenu = () => {
    setTradeSubmenuVisible(!tradeSubmenuVisible);
  };

  const handleTabChange = (tab, shouldNavigate) => {
    dispatch(setCurrentTab(tab));
    closeSidebar();
    if (shouldNavigate) {
      navigate("copyTrade");
    }
    window.scrollTo({ top: 0, behavior: "instant" });
  };

  return (
    <>
      {/* <Header /> */}
      {loaders?.isLoading && <LoadingModal />}
      {loaders?.settingUpService && (
        <AuthLoader component="settings" user={user} />
      )}
      {loaders?.signingYouIn && <AuthLoader />}
      {/* {loaders?.showConnectionSuccessModal && <AuthLoader component="connectionsuccess" />} */}
      {/* {loaders?.showConnectionFailModal && <AuthLoader component="connectionfail" handleErrorModal={handleErrorModal} />} */}

      <section className="main_body common_style" style={{ zIndex: -1 }}>
        <div className="container mw-100">
          <div className="row">
            {user?.role === "member" ? (
              <div
                className={`g-0 col-xl-2 col-md-3 col-lg-3 ${
                  user?.firstVisit ? "d-none" : ""
                }`}
              >
                <div
                  className={`sidebar ${!showSidebar && "d-none"} ${
                    openSidebar === true ? "active" : ""
                  }`}
                >
                  {showSidebar && (
                    <button
                      className={`toggle-btn ${
                        openSidebar === true ? "active" : ""
                      }`}
                      onClick={sidebarClick}
                    >
                      <span></span>
                    </button>
                  )}
                  <>
                    <div className="height-set-for-nav for-safari cm-for-mobile">
                      <nav className="site_nav fs14">
                        <p className="business_name cm_business_name">
                          {user?.memberCommunities?.map((com) => (
                            <React.Fragment
                              key={com?.community?._id || com?._id}
                            >
                              {com?.community?.name}
                              <br />
                            </React.Fragment>
                          ))}
                        </p>
                        <ul>
                          <li
                            className={`submenu ${checkActiveMenu(
                              "dashboard"
                            )}`}
                          >
                            <Link
                              to="dashboard"
                              className={`text-dark d-flex align-items-center links ${
                                location.pathname === "/member/dashboard" &&
                                "submenu-items-active"
                              }`}
                              onClick={() => {
                                closeSidebar();
                                dispatch(setCurrentTab(""));
                                if (user?.firstVisit) {
                                  setOnboard(true);
                                }
                                window.scrollTo({
                                  top: 0,
                                  behavior: "instant",
                                });
                              }}
                            >
                              {/* <i className="bi bi-credit-card me-2"></i> */}
                              <img className="img_margin" src={dashboardIcon} />
                              Dashboard
                            </Link>
                          </li>
                          <li
                            className={`submenu ${checkActiveMenu(
                              "copyTrade"
                            )}`}
                          >
                            <Link
                              to="#"
                              className="text-dark d-flex align-items-center links first-step"
                              onClick={() => {
                                toggleSubmenu();
                                setPerformanceSubmenueVisible(false);
                                setSettingSubmenueVisible(false);
                              }}
                            >
                              <img className="img_margin" src={exchangeIcon} />
                              Copy Trade
                              <img
                                className="arrowD"
                                src={tradeSubmenuVisible ? arrowD : arrowUp}
                                alt="arrow"
                              />
                            </Link>
                            <ul
                              className="submenu-items"
                              style={{
                                opacity: tradeSubmenuVisible ? 1 : 0,
                                maxHeight: tradeSubmenuVisible ? "1000px" : "0",
                                transition:
                                  "opacity 0.5s ease, max-height 0.5s ease",
                              }}
                            >
                              <li
                                className={`${
                                  currentTab === "connection" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    navigate("copyTrade");
                                    dispatch(setCurrentTab("connection"));
                                    closeSidebar();
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "instant",
                                    });
                                  }}
                                >
                                  {" "}
                                  Connection
                                </div>
                              </li>
                              <li
                                className={`${
                                  currentTab === "introduction" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    navigate("copyTrade");
                                    dispatch(setCurrentTab("introduction"));
                                    closeSidebar();
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "instant",
                                    });
                                  }}
                                >
                                  {" "}
                                  How to use
                                </div>
                              </li>
                            </ul>
                          </li>

                          <li
                            className={`submenu ${checkActiveMenu(
                              "performance"
                            )}`}
                          >
                            <Link
                              to="performance"
                              className={`text-dark d-flex align-items-center links ${
                                location.pathname === "/member/performance" &&
                                "submenu-items-active"
                              }`}
                              // onClick={() => {
                              //   closeSidebar();
                              //   dispatch(setCurrentTab(""));
                              //   if (
                              //     user?.firstVisit
                              //   ) {
                              //     setOnboard(
                              //       true
                              //     );
                              //   }
                              // }}

                              onClick={() => {
                                navigate("performance");
                                dispatch(setCurrentTab("TradeHistory"));
                                closeSidebar();
                              }}
                            >
                              {/* <i className="bi bi-credit-card me-2"></i> */}
                              <img className="img_margin" src={historyIcon} />
                              Trading history
                            </Link>
                          </li>
                          {/* <li
                            className={`submenu ${checkActiveMenu(
                              "performance"
                            )}`}
                          >
                            <Link
                              to="#"
                              className="text-dark d-flex align-items-center links"
                              onClick={() => {
                                setPerformanceSubmenueVisible(!performanceSubmenueVisible);
                                setTradeSubmenuVisible(false);
                                setSettingSubmenueVisible(false)

                                if (user?.firstVisit) {
                                  setOnboard(true);
                                }
                              }}
                            >
                            //when uncomment code comment this below code
                              {/* <i className="bi bi-credit-card me-2"></i> */}
                          {/* <img
                                className="img_margin"
                                src={historyIcon}
                              />
                              Trading Performance
                              <img className="arrowD" src={performanceSubmenueVisible ? arrowD : arrowUp} alt="arrow" />
                            </Link>
                            {!user?.firstVisit &&
                              <ul className="submenu-items" style={{ opacity: performanceSubmenueVisible ? 1 : 0, maxHeight: performanceSubmenueVisible ? '1000px' : '0', transition: 'opacity 0.5s ease, max-height 0.5s ease' }}>
                                <li className={`${currentTab === "trading" && "submenu-items-active"}`}><div onClick={() => { navigate("performance"); dispatch(setCurrentTab("trading")); closeSidebar() }}>Overview</div></li>


                                <li className={`${currentTab === "TradeHistory" && "submenu-items-active"}`}><div onClick={() => { navigate("performance"); dispatch(setCurrentTab("TradeHistory")); closeSidebar() }}>Trade history</div></li>
                              </ul>
                            }
                          </li> */}
                          <li
                            className={`submenu ${checkActiveMenu(
                              "accountSettings"
                            )}`}
                          >
                            <Link
                              to="#"
                              className="text-dark d-flex align-items-center links"
                              onClick={() => {
                                setSettingSubmenueVisible(
                                  !settingSubmenueVisible
                                );
                                setTradeSubmenuVisible(false);
                                setPerformanceSubmenueVisible(false);

                                if (user?.firstVisit) {
                                  setOnboard(true);
                                }
                              }}
                            >
                              {/* <i className="bi bi-person me-2"></i> */}
                              <img src={subsIcon} className="img_margin" />
                              Settings
                              <img
                                className="arrowD"
                                src={settingSubmenueVisible ? arrowD : arrowUp}
                                alt="arrow"
                              />
                            </Link>
                            {!user?.firstVisit && (
                              <ul
                                className="submenu-items"
                                // style={{ opacity: settingSubmenueVisible ? 1 : 0, maxHeight: settingSubmenueVisible ? '1000px' : '0', transition: 'opacity 0.5s ease, max-height 0.5s ease' }}
                                style={{
                                  display: settingSubmenueVisible
                                    ? "block"
                                    : "none",
                                }}
                              >
                                <li
                                  className={`${
                                    location.pathname ===
                                      "/member/accountSettings" &&
                                    "submenu-items-active"
                                  }`}
                                >
                                  <div
                                    onClick={() => {
                                      navigate("accountSettings");
                                      dispatch(setCurrentTab(""));
                                      closeSidebar();
                                    }}
                                  >
                                    Account & Security
                                  </div>
                                </li>
                                <li
                                  className={`${
                                    currentTab === "subscribe" &&
                                    "submenu-items-active"
                                  }`}
                                >
                                  <div
                                    onClick={() => {
                                      navigate("copyTrade");
                                      dispatch(setCurrentTab("subscribe"));
                                      closeSidebar();
                                      window.scrollTo({
                                        top: 0,
                                        behavior: "instant",
                                      });
                                    }}
                                  >
                                    Subscription & Billing
                                  </div>
                                </li>
                              </ul>
                            )}
                          </li>
                        </ul>
                      </nav>
                      <nav className="site_nav fs14 overflow-unset">
                        <ul>
                          <span className="lines"></span>
                          <li
                            className={`submenu ${checkActiveMenu("support")}`}
                          >
                            {/* <Link
                              to="support"
                              className={`text-dark d-flex align-items-center links ${location.pathname === "/member/support" && "submenu-items-active"}`}
                              onClick={() => {
                                closeSidebar();
                                if (
                                  user?.firstVisit
                                ) {
                                  setOnboard(
                                    true
                                  );
                                }
                              }}
                            >
                              {/* <i className="bi bi-headset me-2"></i> 
                              <img
                                className="img_margin"
                                src={supportIcon}
                              />
                              Support
                            </Link> */}
                          </li>

                          <li className={`submenu`}>
                            <Link
                              className="text-dark d-flex align-items-center links"
                              onClick={() => {
                                // localStorage.setItem(
                                //   "lastVisitedRoute",
                                //   window.location
                                //     .pathname
                                // );

                                // localStorage.removeItem(
                                //   "token"
                                // );
                                // localStorage.removeItem(
                                //   "lastVisitedRoute"
                                // );
                                // localStorage.removeItem("rememberMe")
                                localStorage.clear();
                                dispatch(
                                  storeUser({
                                    user: null,
                                    token: null,
                                  })
                                );
                                dispatch(setSidebar(false));
                                dispatch(socailSignUp(null));
                                navigate("/login");
                                window.scrollTo({
                                  top: 0,
                                  behavior: "instant",
                                });
                              }}
                            >
                              {/* <i className="bi bi-box-arrow-right me-2"></i> */}
                              <img className="img_margin" src={logoutIcon} />
                              Log Out
                            </Link>
                          </li>
                          {/* <li>
                            <p className="powered safari_only cm-for-mobile for-mobile">
                              Powered by <br />{" "}
                              <span>copyyy.it</span>
                            </p>
                          </li> */}
                        </ul>
                      </nav>
                    </div>
                    {/* <p className="powered safari_only cm-for-mobile for-desktop"> */}
                    <p
                      className={`powered safari_only cm-for-mobile ${
                        isBrave ? "forBrave" : ""
                      }`}
                    >
                      Powered by <br /> <span>copyyy.it</span>
                    </p>
                  </>
                </div>
              </div>
            ) : user?.role === "leader" ? (
              // &&
              //   !user?.firstVisit &&
              //   (user?.trialVersion ||
              //       user?.onboarding?.payment ||
              //       (user?.getPaidInCrypto &&
              //           user?.walletInfo?.usdtWalletAddress))
              <>
                <div
                  className={`g-0 col-xl-2 col-md-3 col-lg-3 ${
                    user?.firstVisit ? "d-none" : ""
                  }`}
                >
                  <div
                    className={`sidebar ${!showSidebar && "d-none"} ${
                      openSidebar === true ? "active" : ""
                    } ${isBrave ? "brave-sidebar" : ""}`}
                  >
                    <button
                      className={`toggle-btn ${
                        openSidebar === true ? "active" : ""
                      }`}
                      onClick={() => sidebarClick()}
                    >
                      <span></span>
                    </button>
                    <div
                      className={`height-set-for-nav for-safari ${
                        isBrave ? "brave-class" : ""
                      }`}
                    >
                      <nav
                        className={`site_nav fs14 ${
                          user.role === "leader" && "site_nav_scroll"
                        }`}
                      >
                        <p className="business_name">
                          {user?.leaderCommunity?.subscription?.logo && (
                            <img
                              src={user?.leaderCommunity?.subscription?.logo}
                            />
                          )}
                          {user?.leaderCommunity?.name}
                        </p>
                        <ul>
                          <li
                            className={`submenu ${checkActiveMenu("urlpath")}`}
                          >
                            <Link
                              to="dashboard"
                              className={`text-dark d-flex align-items-center links ${
                                location.pathname === "/leader/dashboard" &&
                                "submenu-items-active"
                              }`}
                              onClick={() => {
                                closeSidebar();
                                if (user?.firstVisit) {
                                  setOnboard(true);
                                }
                                dispatch(setCurrentTab(""));
                              }}
                            >
                              {/* <i className="bi bi-credit-card me-2"></i> */}
                              <img className="img_margin" src={dashboardIcon} />
                              Dashboard
                            </Link>
                          </li>

                          <li className={`submenu`}>
                            <Link
                              to="#"
                              className="text-dark d-flex align-items-center links first-step"
                              onClick={() => {
                                setTradeSubmenuVisible(!tradeSubmenuVisible);
                                // if (tradeSubmenuVisible) {
                                setPerformanceSubmenueVisible(false);
                                setTradingSubmenuVisible(false);
                                setBusinessSubmenuVisible(false);
                                setSettingSubmenueVisible(false);
                                setAppSubmenuVisible(false);
                                // }
                                setIsOpen(false);
                                // dispatch(
                                //     setSidebar(
                                //         false
                                //     )
                                // );
                              }}
                            >
                              <img
                                className="img_margin"
                                src={exchangeIcon}
                                alt="Exchange Icon"
                              />
                              Copy Trade
                              <img
                                className="arrowD"
                                src={tradeSubmenuVisible ? arrowD : arrowUp}
                                alt="arrow"
                              />
                            </Link>
                            <ul
                              className="submenu-items"
                              style={{
                                display: tradeSubmenuVisible ? "block" : "none",
                              }}
                            >
                              <li
                                className={`${
                                  currentTab === "subscription" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    handleTabChange(
                                      "subscription",
                                      location.pathname === "/leader/copyTrade"
                                        ? false
                                        : true
                                    );
                                  }}
                                >
                                  Subscription
                                </div>
                              </li>
                              <li
                                className={`${
                                  currentTab === "connection" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    handleTabChange(
                                      "connection",
                                      location.pathname === "/leader/copyTrade"
                                        ? false
                                        : true
                                    );
                                  }}
                                >
                                  Connection
                                </div>
                              </li>
                              <li
                                className={`${
                                  currentTab === "onboarding" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    handleTabChange(
                                      "onboarding",
                                      location.pathname === "/leader/copyTrade"
                                        ? false
                                        : true
                                    );
                                  }}
                                >
                                  Onboarding info
                                </div>
                              </li>
                              <li
                                className={`${
                                  currentTab === "url" && "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    handleTabChange(
                                      "url",
                                      location.pathname === "/leader/copyTrade"
                                        ? false
                                        : true
                                    );
                                  }}
                                >
                                  URL
                                </div>
                              </li>
                              {/* <li className={`${location.pathname === "/leader/app" && "submenu-items-active"}`}><div onClick={() => { navigate("app"); dispatch(setCurrentTab("")); closeSidebar() }}>App</div></li> */}
                            </ul>
                          </li>

                          <li className={`submenu`}>
                            <Link
                              to="#"
                              className="text-dark d-flex align-items-center links first-step"
                              onClick={() => {
                                setPerformanceSubmenueVisible(
                                  !performanceSubmenueVisible
                                );
                                // if (!performanceSubmenueVisible) {
                                setTradeSubmenuVisible(false);
                                setTradingSubmenuVisible(false);
                                setBusinessSubmenuVisible(false);
                                setSettingSubmenueVisible(false);
                                setAppSubmenuVisible(false);
                                // }

                                // if (
                                //     user?.firstVisit
                                // ) {
                                //     setOnboard(
                                //         true
                                //     );
                                // }
                              }}
                            >
                              <img
                                className="img_margin"
                                src={AnnounceIcon}
                                alt="Exchange Icon"
                                style={{ objectFit: "cover" }}
                              />
                              {/* <AnnounceIcon /> */}
                              Promote
                              <img
                                className="arrowD"
                                src={
                                  performanceSubmenueVisible ? arrowD : arrowUp
                                }
                                alt="arrow"
                              />
                            </Link>
                            <ul
                              className="submenu-items"
                              style={{
                                display: performanceSubmenueVisible
                                  ? "block"
                                  : "none",
                              }}
                            >
                              <li
                                className={`${
                                  currentTab === "createPromotion" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    dispatch(setCurrentTab("createPromotion"));
                                    navigate("performance");
                                    closeSidebar();
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "instant",
                                    });
                                  }}
                                >
                                  Create promo
                                </div>
                              </li>
                              <li
                                className={`${
                                  currentTab === "autoShare" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    dispatch(setCurrentTab("autoShare"));
                                    navigate("performance");
                                    closeSidebar();
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "instant",
                                    });
                                  }}
                                >
                                  Auto-share results
                                </div>
                              </li>
                            </ul>
                          </li>

                          <li className={`submenu`}>
                            <Link
                              to="#"
                              className="text-dark d-flex align-items-center links first-step"
                              onClick={() => {
                                setTradingSubmenuVisible(
                                  !tradingSubmenuVisible
                                );
                                // if (!tradingSubmenuVisible) {
                                setTradeSubmenuVisible(false);
                                setPerformanceSubmenueVisible(false);
                                setBusinessSubmenuVisible(false);
                                setSettingSubmenueVisible(false);
                                setAppSubmenuVisible(false);
                                // }
                                // if (
                                //     user?.firstVisit
                                // ) {
                                //     setOnboard(
                                //         true
                                //     );
                                // }
                              }}
                            >
                              <img
                                className="img_margin"
                                src={historyIcon}
                                alt="Exchange Icon"
                              />
                              Trading
                              <img
                                className="arrowD"
                                src={tradingSubmenuVisible ? arrowD : arrowUp}
                                alt="arrow"
                              />
                            </Link>
                            <ul
                              className="submenu-items"
                              style={{
                                display: tradingSubmenuVisible
                                  ? "block"
                                  : "none",
                              }}
                            >
                              <li
                                className={`${
                                  currentTab === "trading" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    dispatch(setCurrentTab("trading"));
                                    navigate("performance");
                                    closeSidebar();
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "instant",
                                    });
                                  }}
                                >
                                  Overview
                                </div>
                              </li>
                              <li
                                className={`${
                                  currentTab === "TradeHistory" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    dispatch(setCurrentTab("TradeHistory"));
                                    navigate("performance");
                                    closeSidebar();
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "instant",
                                    });
                                  }}
                                >
                                  Trade history
                                </div>
                              </li>
                            </ul>
                          </li>

                          <li className={`submenu`}>
                            <Link
                              to="#"
                              className="text-dark d-flex align-items-center links first-step"
                              onClick={() => {
                                setBusinessSubmenuVisible(
                                  !businessSubmenuVisible
                                );
                                // if (!businessSubmenuVisible) {
                                setTradeSubmenuVisible(false);
                                setPerformanceSubmenueVisible(false);
                                setTradingSubmenuVisible(false);
                                setSettingSubmenueVisible(false);
                                setAppSubmenuVisible(false);
                                // }

                                // if (
                                //     user?.firstVisit
                                // ) {
                                //     setOnboard(
                                //         true
                                //     );
                                // }
                              }}
                            >
                              <img
                                className="img_margin"
                                src={MoneyIcon}
                                alt="Exchange Icon"
                              />
                              Business
                              <img
                                className="arrowD"
                                src={businessSubmenuVisible ? arrowD : arrowUp}
                                alt="arrow"
                              />
                            </Link>
                            <ul
                              className="submenu-items"
                              style={{
                                display: businessSubmenuVisible
                                  ? "block"
                                  : "none",
                              }}
                            >
                              <li
                                className={`${
                                  currentTab === "business" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    navigate("performance");
                                    dispatch(setCurrentTab("business"));
                                    closeSidebar();
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "instant",
                                    });
                                  }}
                                >
                                  Performance
                                </div>
                              </li>
                              <li
                                className={`${
                                  currentTab === "reports" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    navigate("performance");
                                    dispatch(setCurrentTab("reports"));
                                    closeSidebar();
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "instant",
                                    });
                                  }}
                                >
                                  Reports
                                </div>
                              </li>
                              <li
                                className={`${
                                  location.pathname === "/leader/members" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    navigate("members");
                                    dispatch(setCurrentTab(""));
                                    closeSidebar();
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "instant",
                                    });
                                  }}
                                >
                                  Subscribers
                                </div>
                              </li>
                              <li
                                className={`${
                                  currentTab === "payment" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    navigate("copyTrade");
                                    dispatch(setCurrentTab("payment"));
                                    closeSidebar();
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "instant",
                                    });
                                  }}
                                >
                                  Payment
                                </div>
                              </li>
                              <li
                                className={`${
                                  currentTab === "payoutHistory" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    navigate("performance");
                                    dispatch(setCurrentTab("payoutHistory"));
                                    closeSidebar();
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "instant",
                                    });
                                  }}
                                >
                                  Payout history
                                </div>
                              </li>
                            </ul>
                          </li>

                          <li className={`submenu`}>
                            <Link
                              to="#"
                              className="text-dark d-flex align-items-center links first-step"
                              onClick={() => {
                                setAppSubmenuVisible(!appSubmenuVisible);
                                // if (appSubmenuVisible) {
                                setPerformanceSubmenueVisible(false);
                                setTradingSubmenuVisible(false);
                                setBusinessSubmenuVisible(false);
                                setSettingSubmenueVisible(false);
                                setTradeSubmenuVisible(false);
                                // }
                                setIsOpen(false);
                              }}
                            >
                              <img
                                className="img_margin"
                                src={SmartphoneIcon}
                                alt="Exchange Icon"
                              />
                              App
                              <img
                                className="arrowD"
                                src={appSubmenuVisible ? arrowD : arrowUp}
                                alt="arrow"
                              />
                            </Link>
                            <ul
                              className="submenu-items"
                              style={{
                                display: appSubmenuVisible ? "block" : "none",
                              }}
                            >
                              <li
                                className={`${
                                  currentTab === "aboutApp" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    navigate("app");
                                    dispatch(setCurrentTab("aboutApp"));
                                    closeSidebar();
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "instant",
                                    });
                                  }}
                                >
                                  About the app
                                </div>
                              </li>
                              <li
                                className={`${
                                  currentTab === "pricing" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    navigate("app");
                                    dispatch(setCurrentTab("pricing"));
                                    closeSidebar();
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "instant",
                                    });
                                  }}
                                >
                                  Pricing
                                </div>
                              </li>
                              <li
                                className={`${
                                  currentTab === "promoteApp" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    navigate("app");
                                    dispatch(setCurrentTab("promoteApp"));
                                    closeSidebar();
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "instant",
                                    });
                                  }}
                                >
                                  Promote App
                                </div>
                              </li>
                              <li
                                className={`${
                                  currentTab === "order" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    navigate("app");
                                    dispatch(setCurrentTab("order"));
                                    closeSidebar();
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "instant",
                                    });
                                  }}
                                >
                                  Order
                                </div>
                              </li>
                            </ul>
                          </li>

                          {/* <li className={`submenu ${checkActiveMenu(
                                                      "performance"
                                                  )}`}>
                                                      <Link
                                                          to="performance"
                                                          className="text-dark d-flex align-items-center links first-step"
                                                          onClick={() => {
                                                              closeSidebar();
                                                              if (
                                                                  user?.firstVisit
                                                              ) {
                                                                  setOnboard(
                                                                      true
                                                                  );
                                                              }
                                                          }}
                                                      >
                                                          <img className="img_margin" src={historyIcon} alt="Exchange Icon" />
                                                          Performance
                                                      </Link>
                                                  </li> */}

                          <li className={`submenu`}>
                            <Link
                              to="#"
                              className="text-dark d-flex align-items-center links first-step"
                              onClick={() => {
                                setSettingSubmenueVisible(
                                  !settingSubmenueVisible
                                );
                                // if (!settingSubmenueVisible) {
                                setTradeSubmenuVisible(false);
                                setPerformanceSubmenueVisible(false);
                                setTradingSubmenuVisible(false);
                                setBusinessSubmenuVisible(false);
                                setAppSubmenuVisible(false);
                                // }

                                // if (
                                //     user?.firstVisit
                                // ) {
                                //     setOnboard(
                                //         true
                                //     );
                                // }
                              }}
                            >
                              <img
                                className="img_margin"
                                src={subsIcon}
                                alt="Exchange Icon"
                              />
                              Settings
                              <img
                                className="arrowD"
                                src={settingSubmenueVisible ? arrowD : arrowUp}
                                alt="arrow"
                              />
                            </Link>
                            <ul
                              className="submenu-items"
                              style={{
                                display: settingSubmenueVisible
                                  ? "block"
                                  : "none",
                              }}
                            >
                              <li
                                className={`${
                                  location.pathname ===
                                    "/leader/accountSettings" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    navigate("accountSettings");
                                    dispatch(setCurrentTab(""));
                                    closeSidebar();
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "instant",
                                    });
                                  }}
                                >
                                  Account & Security
                                </div>
                              </li>
                              <li
                                className={`${
                                  currentTab === "closeService" &&
                                  "submenu-items-active"
                                }`}
                              >
                                <div
                                  onClick={() => {
                                    navigate("closeService");
                                    dispatch(setCurrentTab("closeService"));
                                    closeSidebar();
                                    window.scrollTo({
                                      top: 0,
                                      behavior: "instant",
                                    });
                                  }}
                                >
                                  Close service
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </nav>
                      <nav
                        className={`site_nav fs14 overflow-unset ${
                          user.role === "leader" && "site_nav_scroll"
                        }`}
                      >
                        <ul>
                          <span className="lines"></span>
                          <li className={`submenu`}>
                            <Link
                              to="support"
                              className={`text-dark d-flex align-items-center links ${
                                location.pathname === "/leader/support" &&
                                "submenu-items-active"
                              }`}
                              onClick={() => {
                                closeSidebar();
                                if (user?.firstVisit) {
                                  setOnboard(true);
                                }
                              }}
                            >
                              {/* <i className="bi bi-headset me-2"></i> */}
                              <img className="img_margin" src={supportIcon} />
                              Support
                            </Link>
                          </li>

                          <li className={`submenu`}>
                            <Link
                              className="text-dark d-flex align-items-center links"
                              onClick={() => {
                                // localStorage.removeItem(
                                //   "token"
                                // );
                                // localStorage.removeItem(
                                //   "lastVisitedRoute"
                                // );
                                // localStorage.removeItem("rememberMe")
                                localStorage.clear();
                                dispatch(
                                  storeUser({
                                    user: null,
                                    token: null,
                                  })
                                );
                                dispatch(setSidebar(false));
                                navigate("/login");
                                window.scrollTo({
                                  top: 0,
                                  behavior: "instant",
                                });
                              }}
                            >
                              {/* <i className="bi bi-box-arrow-right me-2"></i> */}
                              <img className="img_margin" src={logoutIcon} />
                              Log Out
                            </Link>
                          </li>
                        </ul>
                      </nav>
                      <p className="powered safari_only">
                        Powered by <br /> <span>copyyy.it</span>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            ) : user?.role === "admin" ? (
              <div className="g-0 col-xl-2 col-md-3 col-lg-3">
                <div
                  className={`sidebar adminSidebar${
                    openSidebar === true ? "active" : ""
                  }`}
                >
                  <button
                    className={`toggle-btn ${
                      openSidebar === true ? "active" : ""
                    }`}
                    onClick={sidebarClick}
                  >
                    <span></span>
                  </button>
                  <>
                    <nav className="site_nav fs14">
                      <p className="business_name">
                        Business name <br />
                        Could be 2 lines
                      </p>
                      <ul>
                        <li
                          className={`submenu ${checkActiveMenu("dashboard")}`}
                        >
                          <Link
                            to="dashboard"
                            className="text-dark d-flex align-items-center links"
                            onClick={() => {
                              closeSidebar();
                              if (user?.firstVisit) {
                                setOnboard(true);
                              }
                            }}
                          >
                            {/* <i className="bi bi-credit-card me-2"></i> */}
                            <img className="img_margin" src={dashboardIcon} />
                            Dashboard
                          </Link>
                        </li>
                        <li className={`submenu ${checkActiveMenu("clients")}`}>
                          <Link
                            to="clients"
                            className="text-dark d-flex align-items-center links first-step"
                            onClick={closeSidebar}
                          >
                            {/* <i className="bi bi-credit-card me-2"></i> */}
                            <img className="img_margin" src={Clients} />
                            Clients
                          </Link>
                        </li>
                        <li
                          className={`submenu ${checkActiveMenu(
                            "performance"
                          )}`}
                        >
                          <Link
                            to="performance"
                            className="text-dark d-flex align-items-center links"
                            onClick={() => {
                              closeSidebar();
                              if (user?.firstVisit) {
                                setOnboard(true);
                              }
                            }}
                          >
                            {/* <i className="bi bi-credit-card me-2"></i> */}
                            <img className="img_margin" src={historyIcon} />
                            Statistics
                          </Link>
                        </li>
                        <li
                          className={`submenu ${checkActiveMenu("billings")}`}
                        >
                          <Link
                            to="billings"
                            className="text-dark d-flex align-items-center links first-step"
                            onClick={closeSidebar}
                          >
                            {/* <i className="bi bi-credit-card me-2"></i> */}
                            <img className="img_margin" src={Billing} />
                            Billings
                          </Link>
                        </li>
                        <li
                          className={`submenu ${checkActiveMenu("promotions")}`}
                        >
                          <Link
                            to="promotions"
                            className="text-dark d-flex align-items-center links first-step"
                            onClick={closeSidebar}
                          >
                            {/* <i className="bi bi-credit-card me-2"></i> */}
                            <img className="img_margin" src={Promotions} />
                            Promotions
                          </Link>
                        </li>
                        <li
                          className={`submenu ${checkActiveMenu(
                            "helpArticles"
                          )}`}
                        >
                          <Link
                            to="helpArticles"
                            className="text-dark d-flex align-items-center links first-step"
                            onClick={closeSidebar}
                          >
                            {/* <i className="bi bi-credit-card me-2"></i> */}
                            <img className="img_margin" src={HelpArticles} />
                            Help Articles
                          </Link>
                        </li>
                        <li
                          className={`submenu ${checkActiveMenu(
                            "accountSettings"
                          )}`}
                        >
                          <Link
                            to="accountSettings"
                            className="text-dark d-flex align-items-center links"
                            onClick={() => {
                              closeSidebar();
                              if (user?.firstVisit) {
                                setOnboard(true);
                              }
                            }}
                          >
                            {/* <i className="bi bi-person me-2"></i> */}
                            <img src={subsIcon} className="img_margin" />
                            Settings
                          </Link>
                        </li>
                        <span className="lines"></span>
                        <li className={`submenu ${checkActiveMenu("issues")}`}>
                          <Link
                            to="issues"
                            className="text-dark d-flex align-items-center links first-step"
                            onClick={closeSidebar}
                          >
                            {/* <i className="bi bi-credit-card me-2"></i> */}
                            <img className="img_margin" src={Issues} />
                            Issues
                          </Link>
                        </li>
                        <li
                          className={`submenu ${checkActiveMenu(
                            "contactClients"
                          )}`}
                        >
                          <Link
                            to="contactClients"
                            className="text-dark d-flex align-items-center links"
                            onClick={() => {
                              closeSidebar();
                              if (user?.firstVisit) {
                                setOnboard(true);
                              }
                            }}
                          >
                            {/* <i className="bi bi-headset me-2"></i> */}
                            <img className="img_margin" src={supportIcon} />
                            Contact Clients
                          </Link>
                        </li>

                        <li className={`submenu`}>
                          <Link
                            className="text-dark d-flex align-items-center links"
                            onClick={() => {
                              // localStorage.setItem(
                              //   "lastVisitedRoute",
                              //   window.location
                              //     .pathname
                              // );

                              // localStorage.removeItem(
                              //   "token"
                              // );
                              // localStorage.removeItem(
                              //   "lastVisitedRoute"
                              // );
                              localStorage.clear();
                              dispatch(
                                storeUser({
                                  user: null,
                                  token: null,
                                })
                              );

                              navigate("/login");
                              window.scrollTo({ top: 0, behavior: "instant" });
                            }}
                          >
                            {/* <i className="bi bi-box-arrow-right me-2"></i> */}
                            <img className="img_margin" src={logoutIcon} />
                            Log Out
                          </Link>
                        </li>
                      </ul>
                    </nav>
                    <p className="powered safari_only">
                      Powered by <br /> <span>copyyy.it</span>
                    </p>
                  </>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* outletLayout */}
            <div
              className={`col-xl-${
                (!showSidebar && currentTab !== "subscribe") ||
                (!showSidebar && location.pathname === "/member/tradeActive")
                  ? 8
                  : !showSidebar && currentTab === "subscribe"
                  ? 9
                  : 10
              } col-md-9 col-lg-9 ${
                user?.firstVisit ? "mx-auto" : ""
              } px-sm-3 px-md-0 px-lg-2 px-xl-5 ps-xl-5 layoutTop`}
            >
              <Outlet />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
