import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { setLoading } from "../../../redux/loading/loadingSlice";
import { toastify } from "../../../helpers/toast/toastify";
import RenderLinks from "../../../components/links/RenderLinks";

function Pricing({ setTab, setOrder }) {
    const [activeTab, setActiveTab] = useState("monthly");
    const user = useSelector((value) => value?.user?.user);
    const api = useApi();
    const dispatch = useDispatch();

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const monthlyPackages = [
        {
            title: "$69 - SMALL",
            description: (
                <>
                    <p>Max. 1,000 users* / month</p>
                    <p>- iOS + Android</p>
                </>
            ),
            type: "monthly1",
        },
        {
            title: "$89 - MEDIUM",
            description: (
                <>
                    <p>Max. 5,000 users* / month</p>
                    <p>- iOS + Android</p>
                    <p>- Push notifications</p>
                </>
            ),
            type: "monthly2",
        },
        {
            title: "$149 - LARGE",
            description: (
                <>
                    <p>Max. 50,000 users* / month</p>
                    <p>Everything in ‘medium package’ plus:</p>
                    <p>- Publish app to Amazon + Huawei app stores</p>
                    {/* <p>- App Review Reminders to users</p> */}
                </>
            ),
            type: "monthly3",
        },
        {
            title: "$329 - XL",
            description: (
                <>
                    <p>Unlimited users / month</p>
                    <p>Everything in ‘large package’ plus:</p>
                    <p>- Priority Support</p>
                    {/* <p>- Custom Distribution Outside of App Stores</p> */}
                </>
            ),
            type: "monthly4",
        },
    ];

    const yearlyPackages = [
        {
            title: "$690 - SMALL",
            description: (
                <>
                    <p>Max. 1,000 users* / month</p>
                    <p>- iOS + Android</p>
                </>
            ),
            type: "yearly1",
        },
        {
            title: "$890 - MEDIUM",
            description: (
                <>
                    <p>Max. 5,000 users* / month</p>
                    <p>- iOS + Android</p>
                    <p>- Push notifications</p>
                </>
            ),
            type: "yearly2",
        },
        {
            title: "$1490 - LARGE",
            description: (
                <>
                    <p>Max. 50,000 users* / month</p>
                    <p>Everything in ‘medium package’ plus:</p>
                    <p>- Publish app to Amazon + Huawei app stores</p>
                    {/* <p>- App Review Reminders to users</p> */}
                </>
            ),
            type: "yearly3",
        },
        {
            title: "$3290 - XL",
            description: (
                <>
                    <p>Unlimited users / month</p>
                    <p>Everything in ‘large package’ plus:</p>
                    <p>- Priority Support</p>
                    {/* <p>- Custom Distribution Outside of App Stores</p> */}
                </>
            ),
            type: "yearly4",
        },
    ];

    const handleOrderClick = (packageData) => {
        setTab("order");
        setOrder({
            title: packageData.title,
            description: packageData.description,
            type: packageData.type,
        });
    };

    return (
        <React.Fragment>
            <div className="pricing pricingJs">
                <div className="mx-536">
                    <div className="title">
                        <h3>Pricing</h3>
                        <ul>
                            <li
                                className={
                                    activeTab === "monthly" ? "active" : ""
                                }
                                onClick={() => handleTabChange("monthly")}
                            >
                                Monthly
                            </li>
                            /
                            <li
                                className={
                                    activeTab === "yearly" ? "active" : ""
                                }
                                onClick={() => handleTabChange("yearly")}
                            >
                                Yearly (2 months discount!)
                            </li>
                        </ul>
                    </div>
                    <div
                        className={
                            activeTab === "monthly" ? "monthly" : "d-none"
                        }
                    >
                        <div className="block">
                            {monthlyPackages.map((p, index) => (
                                <div className="card" key={index}>
                                    <div className="text">
                                        <h4>{p.title}</h4>
                                        <p>{p.description}</p>
                                    </div>
                                    <div className="d-flex justify-content-end h-100">
                                        <button
                                            className="black-btn promoteBtn"
                                            onClick={() => handleOrderClick(p)}
                                        >
                                            Order
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <h5 className="fs15 m-6863">
                                + 499 onboarding fee for all packages{" "}
                            </h5>
                            <p className="mt-5 fs12">
                                * Each device the app is opened on counts as 1
                                user.
                            </p>
                            <p className="fs12">
                                **Custom Distribution Outside of App Stores
                                enables you to publish the app on your website.
                            </p>
                            <RenderLinks page="appPricing" />
                        </div>
                    </div>
                    <div
                        className={activeTab === "yearly" ? "yearly" : "d-none"}
                    >
                        <div className="block">
                            {yearlyPackages.map((p, index) => (
                                <div className="card" key={index}>
                                    <div className="text">
                                        <h4>{p.title}</h4>
                                        <p>{p.description}</p>
                                    </div>
                                    <div className="d-flex justify-content-end h-100">
                                        <button
                                            className="black-btn promoteBtn"
                                            onClick={() => handleOrderClick(p)}
                                        >
                                            Order
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <h5 className="fs15">
                                + 499 onboarding fee for all packages{" "}
                            </h5>
                            <p className="fs12">
                                Customising. Design. Coding. Account+process
                                handling. Emails. It all takes time. And people.
                            </p>
                            <p className="mt-5 fs12">
                                * Each device the app is opened on counts as 1
                                user.
                            </p>
                            <p className="fs12">
                                **Custom Distribution Outside of App Stores
                                enables you to publish the app on your website.
                            </p>
                            <RenderLinks page="appPricing" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Pricing;
