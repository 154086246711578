import React, { useEffect, useState } from "react";
import Select from "react-select";

function SettingsSelect({ title, data, setData }) {
    const options = [
        {
            value: "Yes",
            label: "Yes",
        },
        {
            value: "No",
            label: "No",
        },
    ];

    const [value, setValue] = useState();

    const handleChange = (val) => {
        if (val.value === "Yes") {
            setData(true);
            setValue(val);
            // Set the label to the title when "Yes" is selected
        } else {
            setData(false);
            setValue(val);
        }
    };

    useEffect(() => {
        if (data) {
            setValue({
                value: "Yes",
                label: "Yes",
            });
        } else {
            setValue({
                value: "No",
                label: "No",
            });
        }
    }, [data, title]);

    return (
        <React.Fragment>
            <div className="select">
                <Select
                    value={value}
                    options={options}
                    onChange={handleChange}
                    isSearchable={false}
                    placeholder={title}
                    styles={{
                        singleValue: (base) => ({
                            ...base,
                            display: "flex",
                            alignItems: "center",
                        }),
                        dropdownIndicator: (provided, state) => ({
                            ...provided,
                            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
                            transition: 'transform 0.3s ease',
                        }),
                    }}
                />
            </div>
        </React.Fragment>
    );
}

export default SettingsSelect;