import React from "react";
import loaderGif from "../../assets/images/loading.gif";

export default function LoadingModal() {
    return (
        <div className="loader">
            <img src={loaderGif} alt="loaderGif" />
        </div>
    );
}
