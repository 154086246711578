import React, { useEffect, useState } from "react";
import close_danger from "../../assets/images/close_danger.svg";
import { toastify } from "../../helpers/toast/toastify";

export default function AddDiscountModal({ yesAction, noAction, discount }) {
    const [input, setInput] = useState("");
    const [percentage, setPercentage] = useState("");

    useEffect(() => {
        if (discount) {
            setInput(discount.code);
            setPercentage(discount?.percentage);
        }
    }, [discount]);

    return (
        <div className="modal fade show zoomed h-100">
            <div className="modal-dialog modal-sm modal-dialog-centered">
                <div className="modal-content" style={{ marginTop: "15vh" }}>
                    <div className="modal-body p-0 position-relative text-center">
                        <img
                            src={close_danger}
                            alt="img"
                            className="sub-crossicon position-absolute cursor-pointer"
                            onClick={noAction}
                        />

                        <div className="mx-3 mt-5">
                            <p className="fs16 text-700 text-dark">
                                Discount code should consist 8 letters or
                                numbers and it applies to the first month of
                                membership for new members.
                                {/* Enter a unique discount code consisting of 8 alphanumeric values */}
                            </p>
                        </div>
                        <div className="d-flex mt-4 flex-column mx-5 text-left">
                            <label>Discount Code</label>
                            <input
                                className="p-2"
                                value={input}
                                onChange={(e) => {
                                    if (
                                        /^[a-zA-Z0-9]+$/.test(e.target.value) ||
                                        e.target.value === ""
                                    ) {
                                        setInput(e.target.value.toLowerCase());
                                    }
                                }}
                                maxLength={8}
                                placeholder="Enter discount code"
                            />
                        </div>
                        <div className="d-flex mt-4 flex-column mx-5 text-left">
                            <label>Discount Percentage</label>
                            <input
                                className="p-2"
                                value={percentage}
                                onChange={(e) => {
                                    if (
                                        (/^[+-]?\d+(\.\d+)?(?!\.\d+)$/.test(
                                            e.target.value
                                        ) &&
                                            parseInt(e.target.value) <= 100 &&
                                            e.target.value !== "0") ||
                                        e.target.value === ""
                                    ) {
                                        setPercentage(e.target.value);
                                    }
                                }}
                                maxLength={3}
                                placeholder="Enter percentage value"
                            />
                        </div>
                        <div className="row mx-0 mt-4">
                            <div className="col-6 px-0 border-top border-dark py-4 d-flex align-items-center justify-content-center">
                                <p
                                    className="click_action fs16 text-dark"
                                    onClick={() => {
                                        if (input.length !== 8) {
                                            toastify(
                                                "Please enter at least 8 characters."
                                            );
                                        } else if (!percentage) {
                                            toastify(
                                                "Please enter discount percentage."
                                            );
                                        } else {
                                            yesAction(input, percentage);
                                        }
                                    }}
                                >
                                    Add
                                </p>
                            </div>
                            <div className="col-6 px-0 border-top border-start border-dark py-4 d-flex align-items-center justify-content-center">
                                <p
                                    className="click_action fs16 text-dark"
                                    onClick={noAction}
                                >
                                    Cancel
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
