import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/button/Button";
import { useNavigate } from "react-router-dom";
import useApi from "../../helpers/apiHelper/requestHelper";
import { setLoading } from "../../redux/loading/loadingSlice";
import { toastify } from "../../helpers/toast/toastify";
import { updateUser } from "../../redux/users/usersSlice";

export default function LeaderTrial() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const api = useApi();
    const user = useSelector((state) => state?.user?.user);

    const handleUpdate = async () => {
        dispatch(setLoading(true));

        const response = await api("post", "users/update", {
            trialVersion: true,
        });
        console.log("Trial resp", response);
        if (response?.status) {
            dispatch(setLoading(false));

            dispatch(updateUser(response?.data?.user));
            if (response?.data?.user?.trialVersion) {
                toastify("Your trial version has been started!", "success");
            }
            navigate("/leader/subscriptionPlans");
        } else {
            dispatch(setLoading(false));

            toastify(response?.message);
        }
    };

    const handleSkip = async () => {
        if (!user?.trialVersion) {
            navigate("/leader/subscriptionPlans");
            return;
        }
        dispatch(setLoading(true));

        const response = await api("post", "users/update", {
            trialVersion: false,
        });
        console.log("Trial resp", response);
        if (response?.status) {
            dispatch(setLoading(false));

            dispatch(updateUser(response?.data?.user));

            navigate("/leader/subscriptionPlans");
        } else {
            dispatch(setLoading(false));

            toastify(response?.message);
        }
    };

    return (
        <>
            <div className="trial_section" style={{ padding: "0px 36px" }}>
                <section>
                    <div>
                        {user?.firstVisit && (
                            <h4 className="community_name mb-4 ">
                                {user?.leaderCommunity?.name}
                            </h4>
                        )}
                    </div>
                    <div className="mb-5 text-uppercase">
                        <h1>
                            TEST IT OR <br /> LAUNCH IT
                        </h1>
                    </div>
                    {/* <div className="mb-5">
                        <h2 className="bold text-uppercase">
                            You have a trial period <br /> for 5 days
                        </h2>
                    </div> */}
                    {/* <div className="mb-5">
                        <h3 className="bold">Launch your copy trade</h3>
                    </div> */}
                    <div className="mb-3">
                        <Button
                            name="Test copy trade"
                            handleClick={() => {
                                handleUpdate();
                            }}
                        />
                    </div>
                    <Button
                        name="Launch copy trade"
                        handleClick={() => {
                            handleSkip();
                        }}
                    />
                </section>
            </div>
        </>
    );
}
