import React, { useEffect, useState } from "react";
import { toastify } from "../../helpers/toast/toastify";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import { api } from "../../helpers/apiHelper/requestHelper";
import { useDispatch, useSelector } from "react-redux";
import { storeUser } from "../../redux/users/usersSlice";
import { Form } from "react-bootstrap";
import Footer from "../../components/footer/Footer";
import Header from "../../components/Header";
import useApi from "../../helpers/apiHelper/requestHelper";
import Button from "../../components/button/Button";
import { setLoading } from "../../redux/loading/loadingSlice";
import { useAnimate, usePresence, stagger } from "framer-motion";
import InputComponent from "../../components/input/InputComponent";

export default function VerifyEmail() {
    const api = useApi();
    const [otp, setOtp] = useState("");
    const [data, setData] = useState({});
    const [codeIncorrect, setCodeIncorrect] = useState(false);
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const numberRegex = new RegExp(`^[0-9]*$`);
    const user = useSelector((state) => state?.user?.user);
    const [focusedInput, setFocusedInput] = useState(null);
    const [isPresent, safeToRemove] = usePresence();
    const [scope, animate] = useAnimate();

    useEffect(() => {
        setData(state);
    }, []);

    useEffect(() => {
        if (user) {
            if (user?.role === "leader") {
                navigate("/leader/dashboard", { replace: true });
            } else if (user?.role === "member") {
                navigate("/member/dashboard", { replace: true });
            } else if (user?.role === "admin") {
                navigate("/admin/dashboard", { replace: true });
            }
        }
    }, [window.location, user]);

    const handleResendCode = async () => {
        const response = await api("post", "auth/login", data);
        console.log("Data", data);

        if (response?.status) {
            toastify(response?.message, "success");
            console.log("handle resend", response);
        }
    };

    const handleFocus = (inputName) => {
        setFocusedInput(inputName);
    };

    const handleVerify = async () => {
        dispatch(setLoading(true));
        if (!otp || otp.length < 6) {
            dispatch(setLoading(false));
            // toastify("Please enter a 6 digit Code sent to your email address.");
            setCodeIncorrect(true);
        } else {
            const response = await api("post", "auth/verifyEmailOtp", {
                email: data?.email,
                otp,
            });
            if (response?.status) {
                dispatch(setLoading(false));
                toastify("Email verified successfully.", "success");
                if (state?.resetPass) {
                    navigate("/login/createPassword", {
                        state: { email: data?.email },
                    });
                } else {
                    dispatch(setLoading(false));
                    dispatch(storeUser(response?.data));
                    localStorage.setItem("token", response?.data?.token);
                    if (user?.role === "member") {
                        navigate("/member/dashboard", { replace: true });
                    } else if (user?.role === "leader") {
                        navigate("/leader/dashboard", { replace: true });
                    } else if (user?.role === "admin") {
                        navigate("/admin/dashboard");
                    }
                }
            } else {
                dispatch(setLoading(false));
                toastify(response?.message);
            }
        }
    };

    useEffect(() => {
        if (isPresent) {
            const enterAnimation = async () => {
                await animate(
                    scope.current,
                    { x: [-100, 0], opacity: [0, 1] }, // Add x property for horizontal movement
                    { duration: 0.5, delay: stagger(0.2) }
                );
            };
            enterAnimation();
        } else {
            const exitAnimation = async () => {
                await animate(
                    scope.current,
                    { x: [-100, 0], opacity: [0, 1] }, // Add x property for horizontal movement
                    { duration: 0.5, delay: stagger(0.2) }
                );
                safeToRemove();
            };
            exitAnimation();
        }
    }, [scope, isPresent]);

    return (
        <div className="common_style" ref={scope}>
            <Header />

            <section className="sign_section d-flex align-items-center">
                <div className="login_card account_card">
                    <div className="container">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-sm-12 px-sm-3 px-5">
                                <div className="">
                                    <h2 className="fs20 li_h30 text_GBlack mb-9">
                                        Check your email{" "}
                                    </h2>
                                    <h5 className="fs14 mb-26 text-center m-0">
                                        Use the code sent to your email
                                    </h5>
                                </div>

                                {/* <p className="mb-3 ps-2">
                                    Please check your spam folder too
                                </p> */}

                                <Form.Group
                                    controlId="formOtp"
                                    className={` ${codeIncorrect ? "code-incorrect" : ""
                                        }`}
                                >
                                    {/* <Form.Label>Enter the code here</Form.Label> */}
                                    <InputComponent
                                        type="text" // or "password" based on your requirement
                                        label="Enter OTP"
                                        focusedInput={otp ? "focused" : ""}
                                        handleFocus={handleFocus}
                                        value={otp}
                                        maxLength={6}
                                        onChange={(inputValue) => {
                                            if (
                                                numberRegex.test(inputValue) ||
                                                inputValue === ""
                                            ) {
                                                setOtp(inputValue);
                                            }
                                        }}
                                    />
                                    {codeIncorrect && (
                                        <p className="red-text fs15">
                                            Code incorrect
                                        </p>
                                    )}
                                </Form.Group>
                                <div className="">
                                    <div className="mt-19">
                                        <Button
                                            name="Verify email"
                                            handleClick={handleVerify}
                                            className="newCreateButton"
                                        />
                                    </div>
                                    <div className="mt-12 text-center">
                                        <Link
                                            className="newSignLink"
                                            style={{ cursor: "pointer" }}
                                            onClick={handleResendCode}
                                        >
                                            Resend code
                                        </Link>
                                    </div>
                                    {/* <button
                  className="btn btn-success radius min_h57 w-100 fs20"
                  onClick={() => handleVerify()}
                >
                  Verify
                </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    );
}
