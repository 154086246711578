import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Introduction from "./Introduction";
import Connection from "./Connection";
import Payment from "./Payment";
import Url from "./RiskManagement";
import OnboardingUser from "./OnboardingUser";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import CheckMark from "../../../assets/images/check.svg";
import Subscribe from "./Subscribe";
import RiskManagement from "./RiskManagement";
import { setResponseLoading } from "../../../redux/loading/loadingSlice";
import { setCurrentTab } from "../../../redux/links/linksSlice";

function Index() {
    // const [currentTab, setCurrentTab] = useState("introduction");
    const [community, setCommunity] = useState(null);
    const { NP_id } = useParams();

    const user = useSelector((value) => value?.user?.user);
    const loaders = useSelector((state) => state?.loader);
    const { currentTab } = useSelector(state => state.links)
    const userCommunity = useSelector(state => state.community?.community)

    const { id } = useParams();
    const { state } = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, [user]);

    useEffect(() => {
        if (id) {
            dispatch(setCurrentTab("subscribe"));
        }
    }, [id]);
    console.log("====state in connection", state)

    /////  hide for show loader with connection binance ///
    // useEffect(() => {
    //     if (state === null) {
    //         console.log("Inside state null");
    //         dispatch(setResponseLoading(false));
    //     }
    // }, []);

    useEffect(() => {
        if (NP_id) {
            dispatch(setCurrentTab("subscribe"));
        }
    }, [NP_id]);

    //// uncommecnt for connection loader issue
    // useEffect(() => {
    //     if (loaders?.awaitingResponse) {
    //         const timeoutId = setTimeout(() => {
    //             dispatch(setResponseLoading(false));
    //         }, 3000);

    //         // Cleanup the timeout to avoid potential memory leaks
    //         return () => clearTimeout(timeoutId);
    //     }
    // }, [loaders?.awaitingResponse]);

    useEffect(() => {
        if (state) {
            const { tab, connect } = state;

            if (tab) {
                dispatch(setCurrentTab(tab));
            }

            // if (!connect) {
            //     console.log("Inside not connect");
            //     dispatch(setResponseLoading(false));
            // } else 
            if (connect) {
                console.log("Inside connect");
                dispatch(setResponseLoading(true));

                // const delay = 3000;
                // const timeoutId = setTimeout(() => {
                //     dispatch(setResponseLoading(false));
                // }, delay);

                // // Clean up the timeout to avoid side effects
                // return () => clearTimeout(timeoutId);
            }
            // else if (bybit) {
            //     console.log("Inside bybit");

            //     setTimeout(() => {
            //         dispatch(setResponseLoading(false));
            //     }, 3000);
            // }
        }
    }, [state]);

    const handleTabSelect = (selectedTab) => {
        if (
            // (selectedTab === "payment" && !user?.onboarding?.payment) ||
            (selectedTab === "subscribe" && !user?.onboarding?.connect) ||
            (selectedTab === "riskManagement" && !user?.onboarding?.subscribed)
        ) {
            // If the conditions are not met, return early
            return;
        }

        // Update the current tab
        dispatch(setCurrentTab(selectedTab));
    };

    const handleCurrentTab = (tab) => {
        dispatch(setCurrentTab(tab))
    }

    useEffect(() => {
        console.log(currentTab, "CurrentTab")
    }, [currentTab]);

    useEffect(() => {
        if (!["introduction", "connection", "subscribe", "payment"].includes(currentTab)) {
            dispatch(setCurrentTab("introduction"))
        }

    }, []);

    console.log(currentTab == "subscribe", "conditionnn");

    return (
        <div className={`${currentTab == "subscribe" ? "billinContainer" : "container"}`}>
            <div className="row justify-content-center support_wrapper h-100">
                <div className={`${currentTab == "subscribe" ? "billinPageMargin" : "copyTrade"}  mx-auto ${user?.firstVisit && currentTab === "subscribe" && "left-adjustment-copyTrade"}`}>
                    <Tabs
                        defaultActiveKey="connection"
                        id="uncontrolled-tab-example"
                        className="copyTradeTab d-none"
                        activeKey={currentTab}
                        onSelect={handleTabSelect}
                    >
                        <Tab
                            eventKey="introduction"
                            title={
                                <div className="d-flex align-items-center">
                                    {/* {(currentTab === "connection" ||
                                        user?.onboarding?.connect) && (
                                            <img
                                                className="me-1"
                                                src={CheckMark}
                                                alt="introduction"
                                            />
                                        )}{" "}
                                    Introduction */}
                                </div>
                            }
                        >
                            <Introduction setTab={handleCurrentTab} />
                        </Tab>
                        <Tab
                            eventKey="connection"
                            title={
                                <div className="d-flex align-items-center">
                                    {/* {user?.onboarding?.connect && (
                                        <img
                                            className="me-1"
                                            src={CheckMark}
                                            alt="Connection"
                                        />
                                    )}{" "}
                                    Connection */}
                                </div>
                            }
                        >
                            <Connection setTab={handleCurrentTab} />
                        </Tab>
                        <Tab
                            eventKey="subscribe"
                            className="subscribe-tab"
                            title={
                                <div className="d-flex align-items-center">
                                    {/* {user?.onboarding?.subscribed && (
                                        <img
                                            className="me-1"
                                            src={CheckMark}
                                            alt="Payment"
                                        />
                                    )}{" "}
                                    Subscribe */}
                                </div>
                            }
                            disabled={!user?.onboarding?.connect}
                        >
                            <Subscribe
                                className=""
                                setTab={handleCurrentTab}
                                setCommunity={setCommunity}
                            />
                        </Tab>
                        {currentTab === "payment" && (
                            <Tab
                                eventKey="payment"
                                title={
                                    <div className="d-flex align-items-center">
                                        {/* {!user?.firstVisit && (
                                            <img
                                                className="me-1"
                                                src={CheckMark}
                                                alt="Onboarding Users"
                                            />
                                        )}{" "}
                                        Payment */}
                                    </div>
                                }
                            // disabled={!user?.onboarding?.payment}
                            >
                                <Payment
                                    setTab={handleCurrentTab}
                                    community={community || userCommunity}
                                />
                            </Tab>
                        )}
                        {/* <Tab
                            eventKey="riskManagement"
                            title={
                                <div className="d-flex align-items-center">
                                    {!user?.firstVisit && (
                                        <img
                                            className="me-1"
                                            src={CheckMark}
                                            alt="Url"
                                        />
                                    )}{" "}
                                    Risk per trade
                                </div>
                            }
                            disabled={!user?.onboarding?.subscribed}
                        >
                            <RiskManagement />
                        </Tab> */}
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default Index;
