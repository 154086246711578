import React from "react";
import Close from "../../assets/images/cancelBlack.svg";
export default function ConfirmationModal({ text, noAction, yesAction, heading, subheading, description }) {
    return (
        <div className="modal fade show zoomed">
            <div
                className="modal-dialog modal-sm modal-dialog-centered"
                style={{ maxWidth: "411px" }}
            >
                <div className="modal-content modal-radius-6px" style={{ marginTop: "20vh" }}>
                    <div className="modal-body p-0 position-relative text-center closeService">
                        <div
                            className="d-flex justify-content-end mx-3 mt-3"
                            onClick={noAction}
                        >
                            <img
                                src={Close}
                                alt="close"
                                className="cursor-pointer cross"
                            />
                        </div>
                        {text ? (
                            <div className="mx-lg-5 mt-23 mx-3">
                                <h3 className="fs20 bold">{text}</h3>
                                <p className="fs16 mt-9 red-text popup-text-for-mobile">
                                    {description}
                                </p>
                            </div>
                        ) : (
                            <div className="mx-lg-5 mt-23 mx-3">
                                <h3 className="fs20 bold red-text">
                                    {heading} <br className="d-lg-block d-md-block d-none" />
                                    {subheading}
                                </h3>
                                <p className="fs16 mt-9 red-text popup-text-for-mobile">
                                    {description}
                                </p>
                            </div>
                        )}
                        <div className="d-flex justify-content-center mb-40 mt-23 redBtn">
                            <button
                                className="red-btn fs13 medium closeBtn"
                                onClick={yesAction}
                            >
                                Ok. Understood
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
