import React from "react";
import { useSelector } from "react-redux";
import LeaderTradeHistory from "./LeaderTradeHistory";
import MemberTradeHistory from "./MemberTradeHistory";

export default function TradeHistory() {
    const userRole = useSelector((value) => value?.user?.user?.role);

    return userRole === "leader" ? (
        <LeaderTradeHistory />
    ) : (
        <MemberTradeHistory />
    );
}
