import React, { useState } from "react";
import CloseCopyTrade from "../../../components/modals/CloseCopyTrade";
import { useDispatch, useSelector } from "react-redux";
import { setResponseLoading, setShowConnectionSuccessModal } from "../../../redux/loading/loadingSlice";
import { toastify } from "../../../helpers/toast/toastify";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { storeUser } from "../../../redux/users/usersSlice";
import { useNavigate } from "react-router-dom";
import AuthLoader from "../../../components/loader/authLoader";


const CloseService = () => {

    const dispatch = useDispatch();
    const api = useApi();
    const navigate = useNavigate();

    const [showModal, setShowModal] = useState(false);
    const [showContent, setshowContent] = useState(true)
    const loaders = useSelector((state) => state?.loader);

    const logOut = () => {
        // localStorage.removeItem("token");
        // localStorage.removeItem("lastVisitedRoute");
        localStorage.clear()
        dispatch(
            storeUser({
                user: null,
                token: null,
            })
        );

        navigate("/login");
    };

    const handleCloseService = async () => {
        setShowModal(false);
        setshowContent(false)
        dispatch(setResponseLoading(true));
        setTimeout(async () => {
            dispatch(setResponseLoading(false))
            dispatch(setShowConnectionSuccessModal(true))
            const response = await api("get", "users/terminateCommunity");
            // dispatch(setLoading(false));
            if (response?.status) {
                setTimeout(() => {
                    dispatch(setShowConnectionSuccessModal(false))
                    logOut();
                }, 2000);
            } else {
                toastify(response?.message);
            }
        }, 4000);
    };

    const noAction = () => {
        setShowModal(false);
    };


    return (
        <>
            {
                loaders?.showConnectionSuccessModal ? (
                    <AuthLoader component="connectionsuccess" closeSubHeadline="Log in to create new copy trade service." closeHeadline="Service Closed" />
                ) :
                    (loaders?.showConnectionFailModal) ?
                        (<AuthLoader component="connectionfail" connectionFailMessage={loaders?.connectionFailMessage} />)
                        :
                        loaders?.awaitingResponse ? (
                            <AuthLoader component="connection" closeSubHeadline="Please hold. You’ll be logged out when done." closeHeadline="Closing Service" />
                        ) :
                            (

                                <>
                                    {showModal && (
                                        <CloseCopyTrade
                                            noAction={noAction}
                                            yesAction={handleCloseService}
                                        />
                                    )}
                                    {showContent &&
                                        <div className="container mt-sm-30">
                                            <div className="row justify-content-center h-100 support_wrapper">
                                                <div className="col-12">
                                                    <div className="closeTrade mx-auto">
                                                        <h3>Close your copy trade</h3>
                                                        <p>
                                                            You will close your copy trade
                                                            service immediately (and all
                                                            active copy trades) when
                                                            clicking this button. We highly
                                                            recommend telling your community
                                                            before doing so. We do not offer
                                                            any refunds for you or your
                                                            members. Your app service will
                                                            be closed too. However, you need
                                                            to remove it from the app stores
                                                            yourself.
                                                        </p>
                                                        <button
                                                            className="text-center fs13 text_GM"
                                                            onClick={() =>
                                                                setShowModal(true)
                                                            }
                                                        >
                                                            Close my copy trade now
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </>
                            )
            }
        </>
    )
}

export default CloseService;