import React, { useEffect, useLayoutEffect, useState } from "react";
import closeSuccessIcon from "../../assets/images/close_success.svg";
import { useNavigate } from "react-router-dom";
import useApi from "../../helpers/apiHelper/requestHelper";
import { useSelector } from "react-redux";
import InputComponent from "../../components/input/InputComponent";
import { useDispatch } from "react-redux";
import { setCurrentTab } from "../../redux/links/linksSlice";
import successGif from "../../assets/images/success.gif"
import { setLoading } from "../../redux/loading/loadingSlice";

export default function EmailGetter() {
  const api = useApi();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [email, setEmail] = useState("");
  const [focusedInput, setFocusedInput] = useState(null);
  const [showSuccessModa, setShowSuccessModal] = useState(false)
  const user = useSelector((state) => state?.user?.user);

  // const signup = useSelector((state) => state?.);

  useLayoutEffect(() => {
    setShowSuccessModal(true)
    localStorage.removeItem('AllowSocialLogin');
  }, [])

  useEffect(() => {
    if (showSuccessModa) {
      setTimeout(() => {
        setShowSuccessModal(false)
      }, 2000);
    }
  }, [showSuccessModa])

  const handleNext = async () => {
    dispatch(setLoading(true))
    const updatedUser = await api("post", "users/update", {
      email,
      binanceLogin: { ...user.binanceLogin, emailVerified: true }
    });
    if (updatedUser?.status) {
      if (updatedUser?.data?.user?.role == "member") {
        dispatch(setLoading(false))
        navigate("/member/copyTrade", { replace: true })
        dispatch(setCurrentTab("subscribe"))
      } else {
        dispatch(setLoading(false))
        navigate("/leader/copyTrade", { replace: true })
        dispatch(setCurrentTab("payment"))
      }
    }
  };

  const handleFocus = (inputName) => {
    setFocusedInput(inputName);
  };

  return (
    <>
      {showSuccessModa ?
        <div className="connectLoader">
          <div className="text-center">
            <h3 className="fs20 bold li_h30">Conected successful, <br /> please hold</h3>
            <img src={successGif} alt="loaderGif" height={120} />
          </div>
        </div>
        :
        <div className="wrapper">
          <div className="common_style">
            <section className="sign_section d-flex align-items-center">
              <div className="login_card verify_card"
              // style={{ border: "none" }}
              >
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-sm-12 px-0">
                      <div className="">
                        <h2 className="fs20 li_h30 text_GBlack m-0 mb-38-for-heaidng">
                          {user?.memberCommunities[0]?.community?.name?.toUpperCase()}
                        </h2>
                      </div>
                      <h2 className="fs20 text_GB mb-9">
                        What's Your Email?
                      </h2>
                      <p className="justify-content-center fs14 mb-26 text-center">For Important Service Update</p>
                      <div className="" style={{ marginTop: "38px" }}>
                        <InputComponent
                          type="email"
                          label="Email"
                          focusedInput={email ? "focused" : ""}
                          handleFocus={handleFocus}
                          value={email}
                          onChange={setEmail}
                        />
                      </div>
                      <div className="mt-19">
                        <div className="d-flex justify-content-end">
                          <button
                            onClick={handleNext}
                            className="newCreateButton"
                          >
                            Next
                          </button>
                        </div>
                        {/* <button
                                        className="btn btn-success radius min_h57 w-100 fs20"
                                        data-bs-toggle="modal"
                                        data-bs-target="#RESETPASSWORD"
                                        role="button"
                                        onClick={() => resetPassword()}
                                        >
                                        RESET PASSWORD
                                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div
              className="modal fade"
              id="RESETPASSWORD"
              tabIndex="-1"
              aria-labelledby="RESETPASSWORDLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-sm modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body position-relative">
                    <img
                      src={closeSuccessIcon}
                      alt="img"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      className="position-absolute"
                      role="button"
                    />

                    <div className="ps-5">
                      <p className="fs16 text-700">Email sent!</p>
                      <p className="fs16">
                        Follow the link in that email!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <Footer /> */}
            <footer className="site_footer">
              <div className="container">
                <div className="row">
                  <div className="login_footer" >
                    <div className="col-12 pb-sm-5 pb-0">

                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <p className="text-center">
                          <span className="d-block text-center">Powered by</span> <a href="https://www.copyyy.it/" className="mail-page-logo" target="_blank">copyyy.it</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </footer>
          </div>
        </div>
      }
    </>
  );
}
