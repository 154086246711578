import React, { useEffect, useState } from "react";
import "./OpenTrades.css";
import { Link } from "react-router-dom";
import useApi from "../../helpers/apiHelper/requestHelper";

const OpenTrades = () => {
  const api = useApi();
  const [openPosition, setOpenPosition] = useState([]);
  const [activeOrders, setActiveOrders] = useState([]);
  const [totalOpenTrade, setTotalOpenTrade] = useState([]);
  const [showAll, setShowAll] = useState(false);

  const callApi = async () => {
    const response = await api("post", "livetrade/getLiveTrade");
    if (response?.data) {
      const data = response.data;

      const openPositions = data?.[0]?.openPositions || [];
      setOpenPosition(openPositions);

      const activeOrders = data?.[1]?.activeOrders || [];
      setActiveOrders(activeOrders);

      setTotalOpenTrade(openPositions?.length);
    }
  };

  useEffect(() => {
    callApi();
  }, []);

  return (
    <div
      className="OpenTradeBox"
      style={{
        overflowY: showAll ? "scroll" : "",
      }}
    >
      <div className="headingBox">
        <p className="heading CP-fs-18 fw-medium">
          Open trades: {totalOpenTrade}
        </p>
        <Link
          to=""
          className="d-none d-lg-block"
          onClick={() => {
            setShowAll(!showAll);
          }}
        >
          {showAll ? "See less" : "See all"}
        </Link>
      </div>
      <div className="openTrades">
        {openPosition
          .slice(0, showAll ? openPosition.length : 6)
          ?.map((item, index) => {
            const createdTime = new Date(
              parseInt(item.createdTime || item.updateTime)
            );
            const formattedTime = createdTime.toLocaleString();

            return (
              <div key={index} className="openTradeItem">
                <div className="ContentSide">
                  <p className="title">
                    {item.symbol} │ {item.side}
                  </p>
                  <div className="quantityBox">
                    <p className="desc">QTY: ${item.positionValue}</p>{" "}
                    <p className="desc">{formattedTime}</p>
                  </div>
                </div>
                <div className="numberSide">
                  <p
                    className={`numbers ${item.pnlPercentage < 0 ? "red" : ""}`}
                  >
                    {item.pnlPercentage?.toFixed(2)}%
                  </p>{" "}
                </div>
              </div>
            );
          })}
      </div>

      <div className="text-end d-lg-none">
        <Link
          to=""
          onClick={() => {
            setShowAll(!showAll);
          }}
        >
          {showAll ? "See less" : "See all"}
        </Link>
      </div>
    </div>
  );
};

export default OpenTrades;
