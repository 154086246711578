import React from "react";
import { useSelector } from "react-redux";
import LeaderAccountSettings from "./LeaderAccountSettings";
import MemberAccountSettings from "./MemberAccountSettings";

export default function AccountSettings() {
    const userRole = useSelector((value) => value?.user?.user?.role);

    return userRole === "leader" ? (
        <LeaderAccountSettings />
    ) : (
        <MemberAccountSettings />
    );
}
