import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import Binance from "../../../assets/images/Binance_black.png";
import ByBit from "../../../assets/images/Bybit_black.png";
import Connect1 from "../../../assets/images/connect1.svg";
import Connect2 from "../../../assets/images/connect2.svg";
import { useSelector } from "react-redux";

const countries = [
    {
        value: "Binance",
        label: "",
        icon: Binance,
    },
    {
        value: "ByBit",
        label: "",
        icon: ByBit,
    },
    // {
    //     value: "",
    //     label: "Coming Soon",
    //     icon: Connect1,
    // },
    // {
    //     value: "",
    //     label: "Coming Soon",
    //     icon: Connect2,
    // },
];

const Option = (props) => (
    <components.Option {...props} className="country-option">
        <div className="d-flex align-items-center justify-content-between">
            <div className="logoDiv">
                <img
                    src={props.data.icon}
                    alt="logo"
                    className="country-logo"
                />
                {props.data.label}
            </div>
        </div>
    </components.Option>
);

function ExchangeConnects({ onExchangeChange, credentials }) {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [options, setOptions] = useState([])
    const user = useSelector((value) => value?.user?.user);

    const handleChange = (value) => {
        setSelectedCountry(value);
        if (onExchangeChange) {
            onExchangeChange(value);
        }
    };

    useEffect(() => {
        if (credentials && credentials.exchange) {
            credentials.exchange === "Binance" ? setSelectedCountry(countries[0]) : setSelectedCountry(countries[1])
        }
    }, [])

    useEffect(() => {
       const opt = []
       if(user?.binanceCredentials?.isConnected) {
        opt.push({
            value: "Binance",
            label: "",
            icon: Binance,
        })
        }
        if(user?.bybitCredentials?.isConnected) {
            opt.push({
                value: "ByBit",
                label: "",
                icon: ByBit,
            })
        }
       setOptions(opt)
    },[])

    const SingleValue = ({ children, ...props }) => (
        <components.SingleValue {...props}>
            {selectedCountry ? (
                <div className="d-flex align-items-center justify-content-between">
                    <div className="logoDiv">
                        <img
                            src={selectedCountry.icon}
                            alt="s-logo"
                            className="selected-logo"
                        />
                        {children}
                    </div>
                </div>
            ) : (
                <div className="placeholder">Your New Placeholder Text</div>
            )}
        </components.SingleValue>
    );

    return (
        <React.Fragment>
            <div className="select">
                <Select
                    value={selectedCountry}
                    options={options?.length ? options : countries}
                    onChange={handleChange}
                    isSearchable={false}
                    // menuIsOpen={true}
                    placeholder="Select exchange account(s)"
                    styles={{
                        singleValue: (base) => ({
                            ...base,
                            display: "flex",
                            alignItems: "center",
                        }),
                        dropdownIndicator: (provided, state) => ({
                            ...provided,
                            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
                            transition: 'transform 0.3s ease',
                        }),
                    }}
                    components={{
                        Option,
                        SingleValue,
                    }}
                />
            </div>
        </React.Fragment>
    );
}

export default ExchangeConnects;
