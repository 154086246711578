import React from "react";

export default function Footer({ cypressOtp }) {
    return (
        <footer className="site_footer">
            <div className="container">
                <div className="row">
                    <div className="login_footer">
                        <div className="col-12 pb-sm-5 pb-0">
                            <div className="d-flex justify-content-between text-dark">
                                <p>
                                    Powered by <a href="https://www.copyyy.it/" target="_blank">copyyy.it</a>
                                </p>
                                <ul>
                                    <li>
                                        <h6 className="custom-white-text">
                                            {cypressOtp}
                                        </h6>

                                    </li>
                                    <li>
                                        <a
                                            href="http://copyyy.it/privacy-policies"
                                            target="_blank"
                                        >
                                            Privacy
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="http://copyyy.it/terms-and-conditions"
                                            target="_blank"
                                        >
                                            Terms
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
