import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import useApi from "../../helpers/apiHelper/requestHelper";
Chart.register(...registerables); // Register all available scales

export default function TradeHistoryChart() {
    const api = useApi();
    const [history, setHistory] = useState([]);

    function formatMonthYear(date) {
        let dateObject = new Date(date);
        const options = { month: "short", year: "numeric" };
        return new Intl.DateTimeFormat("en-US", options).format(dateObject);
    }

    useEffect(() => {
        const getTradeHistory = async () => {
            const response = await api("get", "users/getTradeHistory");
            if (response?.status) {
                const monthCounts = {};
                response?.data?.forEach((entry) => {
                    const monthYear = formatMonthYear(entry.timestamp);
                    monthCounts[monthYear] = (monthCounts[monthYear] || 0) + 1;
                });
                const resultArray = Object.entries(monthCounts).map(
                    ([monthYear, count]) => ({
                        month: monthYear,
                        count: count,
                    })
                );
                setHistory(resultArray);
            }
        };

        getTradeHistory();
    }, []);

    const data = {
        labels: history?.map((e) => e.month),
        datasets: [
            {
                label: "Trades",
                data: history?.map((e) => e.count),
                borderColor: "#FFFFFF",
                backgroundColor: "#FFFFFF",
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: true,
            text: "Chart.js Line Chart",
        },
        tooltips: {
            mode: "index",
            intersect: false,
        },
        hover: {
            mode: "nearest",
            intersect: true,
        },
        plugins: {
            legend: {
                display: false,
            },
        },

        scales: {
            x: {
                type: "category", // Specify scale type
                display: false,
                scaleLabel: {
                    display: false,
                    labelString: "Month",
                },
            },
            y: {
                display: false,
                scaleLabel: {
                    display: false,
                    labelString: "Value",
                },
            },
        },
    };

    return (
        <div style={{ height: "75px", width: "100px" }}>
            <Line data={data} options={options} />
        </div>
    );
}
