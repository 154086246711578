import React, { useEffect, useState } from "react";
import close_success from "../../assets/images/cancelBlack.svg";
import { toastify } from "../../helpers/toast/toastify";

export default function OnboardingModal({
    yesAction,
    noAction,
    step,
    heading,
    description,
    handleClick
}) {
    //   useEffect(() => {
    //     if (discount) {
    //       setInput(discount.code);
    //       setPercentage(discount?.percentage);
    //     }
    //   }, [discount]);

    return (
        <div className="modal fade onboard-modal show zoomed" onClick={handleClick}>
            <div className="modal-dialog modal-sm">
                <div className="modal-content">
                    <div className="onboard-modal-body position-relative text-center">
                        <div className="d-flex align-items-center justify-content-center px-3 py-3 h-100">
                            <p>{heading}</p>
                        </div>
                        <img
                            src={close_success}
                            alt="img"
                            className="sub-crossicon position-absolute cursor-pointer"
                            onClick={noAction}
                        />
                        {/* <div className="">
                            <p className="fs16 text-700 text-dark">{step}</p>
                        </div>
                        <div className="mx-3 my-3">
                            <p className="fs16 text-700 text-dark">{heading}</p>
                            <p className="fs16 text-400">{description}</p>
                        </div>  */}
                    </div>
                </div>
            </div>
        </div>
    );
}
