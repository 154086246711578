import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";

export const CustomToastContainer = () => {
    const [scrollPosition, setScrollPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollPosition(window.scrollY);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    // Calculate the top position for the modal
    const modalTop = scrollPosition > 0 ? 0 : "0";

    return (
        <ToastContainer style={{ top: modalTop, position: "fixed" }}>
            {/* Modal content */}
        </ToastContainer>
    );
};
