import React from "react";
import { useSelector } from "react-redux";
import LeaderReferralCodes from "./LeaderReferralCodes";
import MemberReferralCodes from "./MemberReferralCodes";

export default function ReferralCodes() {
    const userRole = useSelector((value) => value?.user?.user?.role);

    return userRole === "leader" ? (
        <LeaderReferralCodes />
    ) : (
        <MemberReferralCodes />
    );
}
