import { useState } from "react";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/button/Button";
import { toastify } from "../../../helpers/toast/toastify";
import { updateUser } from "../../../redux/users/usersSlice";

export default function RiskManagement() {
    const api = useApi();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { exchange } = useParams();
    const { state } = useLocation();
    const user = useSelector((state) => state?.user?.user);
    const [tradePercentage, setTradePercentage] = useState(1);

    const submit = async () => {
        let updatedBinanceCredentials = { ...user?.binanceCredentials };
        let updatedBybitCredentials = { ...user?.bybitCredentials };

        // If exchange is "Binance" and it's connected, update Binance credentials
        if (exchange === "Binance" && user?.binanceCredentials?.isConnected) {
            updatedBinanceCredentials = {
                ...user?.binanceCredentials,
                tradePercentage: tradePercentage,
            };
        }

        // If exchange is "ByBit" and it's connected, update ByBit credentials
        if (exchange === "ByBit" && user?.bybitCredentials?.isConnected) {
            updatedBybitCredentials = {
                ...user?.bybitCredentials,
                tradePercentage: tradePercentage,
            };
        }

        // If no specific exchange is specified, update both Binance and ByBit
        if (exchange === undefined || exchange === "") {
            if (user?.binanceCredentials?.isConnected) {
                updatedBinanceCredentials = {
                    ...user?.binanceCredentials,
                    tradePercentage: tradePercentage,
                };
            }
            if (user?.bybitCredentials?.isConnected) {
                updatedBybitCredentials = {
                    ...user?.bybitCredentials,
                    tradePercentage: tradePercentage,
                };
            }
        }

        const response = await api("post", "users/update", {
            binanceCredentials: updatedBinanceCredentials,
            bybitCredentials: updatedBybitCredentials,
        });

        if (response?.status) {
            toastify("Trade Percentage added successfully.", "success");
            dispatch(updateUser(response?.data?.user));

            if (
                user?.binanceCredentials?.isConnected &&
                user?.bybitCredentials?.isConnected
            ) {
                navigate("/member/tradeActive", { replace: true });
            } else if (state?.selectedBinance && state?.selectedBybit) {
                navigate("/member/dashboard", { replace: true });
            } else {
                navigate("/member/tradeActive", { replace: true });
            }
        } else {
            toastify(response?.message);
        }
    };

    return (
        <div
            className="row justify-content-center less_content h-100 py-0 add-heading-border"
            style={{ padding: "0px 36px" }}
        >
            <div className="col-lg-10 disable_animation_accordion d-flex flex-column">
                {user?.firstVisit && (
                    <h4 className="community_name mb-5">
                        {user?.memberCommunities[0]?.community?.name}
                    </h4>
                )}
                <div className="page_title d-sm-flex align-items-sm-center justify-content-sm-between">
                    <h5 className="fs25 text_GB">Risk management</h5>
                </div>
                <p className="mt-2 fs16">
                    Select the % of your portfolio to use in each trade.
                </p>
                <div className="h-100 d-flex flex-column">
                    <div className="page_form d-grid gap-4 mt-sm-4 pt-sm-3 pb-sm-5">
                        <div className="row d-sm-flex d-grid gap-sm-0 gap-4">
                            <div>
                                <p className="fs16 my-2 gray-color">
                                    Select the %
                                </p>
                            </div>
                            <div className="col-12">
                                <select
                                    className="w-100 form-control"
                                    value={tradePercentage || 1}
                                    onChange={(e) =>
                                        setTradePercentage(e.target.value)
                                    }
                                >
                                    <option value={1}>1%</option>
                                    <option value={2}>2%</option>
                                    <option value={3}>3%</option>
                                    <option value={4}>4%</option>
                                    <option value={5}>5%</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center align-items-center mt-5">
                        <div className="mt-sm-auto mt-5">
                            <Button name="Next" handleClick={submit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
